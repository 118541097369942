import React, { useState, useEffect } from "react";

import {
    useParams,
    useLocation
} from "react-router-dom";

import {
    is_agent
} from '../../../../Services/permissions';

import {
    openChaveLusaAdmin,
    requestGET,
    handleRequestError
} from '../../../utils';

import {
    Box,
    Toolbar,
    Typography,
    IconButton,
    Tooltip,
    CircularProgress,
    TextField,
    Menu,
    MenuItem,
    Grid,
    Button,
    Snackbar,
    Alert
} from '@material-ui/core';

import {
    Help as HelpIcon,
    Chat as ChatIcon,
    MarkUnreadChatAlt as MarkUnreadChatAltIcon,
    LocalPolice,
    VisibilityOff as VisibilityOffIcon,
    AccountBalance,
    PublishedWithChanges
} from '@mui/icons-material';

import { Steps } from 'intro.js-react';

import { tutorial } from './utils';

import Window from "../../../Components/Chavelusa/Window";
import ChaveLusaDialog from "../../../Components/Chavelusa/Dialog";
import PropertyReviewWindow from "../../../Components/Realstate/PropertyReview/Window/Review";
import PropertyReviewChangeStatusDialog from "../../../Components/Realstate/PropertyReview/ChangeStatusDialog";
import FormNotes from "../../../Components/Chavelusa/FormNotes";
import { propertyForm } from '../../../Components/Realstate/Property/Form/launcher';

import Tags from "./Tags";
import Feed from "./Feed";
import Sidebar from "./Sidebar";

import { PROPERTY_STATUS_ARCHIVED } from "../../../../Models/property";


const PropertyPage = () => {

    const url = process.env.REACT_APP_API_URL + '/realstate/properties/';

    // URL Data
    let { id } = useParams();
    const urlSearch = useLocation().search;

    // Get user data from API
    const [loading, setLoading] = useState(true);
    const [notify, setNotify] = useState(undefined);

    const [instance, setInstance] = useState(null);
    const [reviewsPending, setReviewsPending] = useState(undefined); // boolean

    useEffect(() => {
        // Initial loading
        loadInstance(true);
        loadReviewsPending();
    }, [id]);

    const loadInstance = (initialLoading) => {
        // Load property instance from API
        requestGET(url + id + '/')
            .then(response => {
                console.log("GOT instance", response);
                setInstance(response);
                // If initial loading and edit parameter, open edittion window
                if (initialLoading && new URLSearchParams(urlSearch).get("edit"))
                    editProperty(response, false);
            }).catch(error =>
                handleRequestError(
                    error,
                    [],
                    "Error getting instance",
                ).then(() => {
                    window.location.replace("/propriedades/");
                })
            ).finally(() => {
                setLoading(false);
            });
    }

    // Reviews
    const loadReviewsPending = () => {
        // Load property reviews status
        requestGET(process.env.REACT_APP_API_URL + '/realstate/reviews/pending/' + id)
            .then(response => {
                console.log("GOT reviews status", response);
                setReviewsPending(response.messagesPending);
            }).catch(error =>
                handleRequestError(
                    error,
                    [],
                    "Error getting reviews status",
                )
            );
    }
    const [changeStatus, setChangeStatus] = useState(false);

    // Lateral window
    const [rWindow, setWindow] = useState(null);
    const [windowChanged, setWindowChanged] = useState(false);
    const [windowPipe, setWindowPipe] = useState([]);
    useEffect(() => {
        // When window changes, reset changed property
        if (rWindow === null)
            setWindowChanged(false);
        else
            setWindowPipe(wp => {
                wp.push(window);
                return wp;
            })
    }, [rWindow]);
    const windowPipePop = () => {
        if (windowPipe.length === 0)
            return null;
        if (windowPipe.length === 1)
            return windowPipe[windowPipe.length - 1];
        return windowPipe[windowPipe.length - 2];
    }

    // TUTORIAL
    const [showTutorial, setShowTutorial] = React.useState(false);

    const goingToStep = (nextIndex) => {
        // When opening a tutotial tooltip, scroll into element
        // (Specially for mobile which has table horizontal scroll)
        if (tutorial && nextIndex < tutorial.length && document.querySelector(tutorial[nextIndex]['element'])) {
            document.querySelector(tutorial[nextIndex]['element']).scrollIntoView({ behavior: 'smooth' });
        }
    }

    // INSTANCE EDIT
    const editProperty = (newInstance, update = true) => {
        update && setInstance(newInstance);
        setWindow(null);
        setTimeout(() =>
            setWindow(
                propertyForm(
                    newInstance,
                    () => setWindow(null),
                    editProperty,
                    () => setWindowChanged(true)
                )
            )
            , 500
        );
    }

    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"

            pr={rWindow ? 3 : 0}
            sx={{
                margin: 'auto',
                minHeight: '50vh'
            }}
            className='client_agents_list scalable'
        >
            {
                loading &&
                <CircularProgress sx={{ mt: 'auto', mx: 'auto' }} />
            }
            {
                !loading && instance &&
                <Grid container direction="row" xs={12}>
                    { /* MAIN CONTAINER */}
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        xs={!rWindow ? 8 : 6}
                        wrap="nowrap"
                    >
                        <div className="property-header">
                            { /* HEADER */}
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                            >
                                <Grid item>
                                    <Typography variant='h4' sx={{ fontWeight: 500 }}>
                                        {instance.exclusive &&
                                            <Tooltip title="Propriedade exclusiva">
                                                <LocalPolice color="primary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                                            </Tooltip>
                                        }
                                        {instance.bankproperty &&
                                            <Tooltip title="Imóvel da banca">
                                                <AccountBalance color="primary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                                            </Tooltip>
                                        }
                                        {instance.exchange &&
                                            <Tooltip title="Aceita permuta">
                                                <PublishedWithChanges color="primary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                                            </Tooltip>
                                        }
                                        {instance.title}
                                    </Typography>
                                    <Typography variant='caption' color="secondary">Ref. {instance.id}</Typography>
                                </Grid>
                                <Grid container direction="row" ml="auto" width="auto" className="operations">
                                    {
                                        instance.status !== PROPERTY_STATUS_ARCHIVED &&
                                        <Button
                                            onClick={() => editProperty(instance, false)}
                                            color="primary"
                                            variant="outlined"
                                            sx={{ mr: 1, my: 'auto' }}
                                        >
                                            Editar
                                        </Button>
                                    }
                                    <Button
                                        onClick={() => {
                                            setChangeStatus(true);
                                        }}
                                        color="primary"
                                        variant="outlined"
                                        sx={{ mr: 1, my: 'auto' }}
                                    >
                                        Alterar estado
                                    </Button>
                                    <Tooltip title="Conversa da propriedade" className="property-chat-button">
                                        <IconButton
                                            color={reviewsPending ? "error" : "primary"}
                                            onClick={() => setWindow(<PropertyReviewWindow
                                                propertyId={instance.id}
                                                setNotify={setNotify}
                                                reloadPending={loadReviewsPending}
                                            />)}
                                        >
                                            {reviewsPending ? <MarkUnreadChatAltIcon /> : <ChatIcon />}
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>

                            { /* TAGS */}
                            <Tags
                                property={instance}
                                messagesPending={reviewsPending}
                            />

                            { /* NOTES */}
                            <FormNotes
                                noteOriginal={instance.notes}
                                url={process.env.REACT_APP_API_URL + '/realstate/properties/' + instance.id + '/'}
                                attribute={'notes'}
                                onUpdate={(updated) => {
                                    setNotify({ text: 'As notas foram guardadas com sucesso' });
                                    setInstance(updated);
                                }}
                                deactivated={instance.status === 'Q'}
                            />

                        </div>

                        { /* FEED */}
                        <Feed
                            instance={instance}
                            setWindow={setWindow}
                            window={rWindow}
                            windowChanged={() => setWindowChanged(true)}
                            windowChangedState={windowChanged}
                            windowPipePop={windowPipePop}
                            setNotify={(text, error) => setNotify({ text: text, error: error })}
                            onUpdate={loadInstance}
                        />
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-end"
                        xs={!rWindow ? 4 : 6}
                    >
                        <Tooltip title="Ajuda">
                            <IconButton
                                onClick={() => setShowTutorial(true)}
                            >
                                <HelpIcon />
                            </IconButton>
                        </Tooltip>
                        {
                            // Sidebar
                            <Sidebar
                                property={instance}
                            />
                        }
                    </Grid>
                </Grid>
            }

            {
                rWindow &&
                <Window close={() => setWindow(null)} hasChanged={windowChanged}>{rWindow}</Window>
            }

            {
                <Snackbar
                    open={notify != undefined && notify != false}
                    autoHideDuration={6000}
                    onClose={() => setNotify(undefined)}
                >
                    {
                        notify &&
                        <Alert onClose={() => setNotify(undefined)} severity={notify.error ? "error" : "success"} sx={{ width: '100%' }}>
                            {notify.text}
                        </Alert>
                    }
                </Snackbar>
            }

            <Steps
                enabled={showTutorial}
                steps={tutorial}
                initialStep={0}
                onExit={() => setShowTutorial(false)}
                onBeforeChange={goingToStep}
                options={{
                    nextLabel: 'Próximo',
                    prevLabel: 'Anterior',
                    doneLabel: 'Concluir',
                    hidePrev: true,
                    disableInteraction: true
                }}
            />

            {
                <ChaveLusaDialog
                    open={changeStatus}
                    handleClose={() => setChangeStatus(false)}
                    title={"Alterar estado da propriedade"}
                >
                    <PropertyReviewChangeStatusDialog
                        property={instance}
                        onSuccess={() => {
                            setChangeStatus(false);
                            loadInstance();
                        }}
                        setNotify={setNotify}
                    />
                </ChaveLusaDialog>
            }
        </Grid>
    );
}

export default PropertyPage;